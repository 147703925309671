import { toast } from 'react-toastify';
import { ExpertExperience, ExpertDegree, Nickname, Profile } from '@shared/models/Profile.model';
import { ExpertsByDomain } from '@shared/models/ProfileV2.model';
import {
	EventPayload,
	GetAppliablePayload,
	GetAppliableResponse,
	IChatPayload,
	JoinWebinarsCollectionsPayload,
	JoinWebinarsPayload,
	TopicSettingPayload,
	TopicSettingResponse
} from './PayloadTypes';
import { UpDownURL } from '@shared/models/UpDownUrl.model';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { API } from '@api/constants';
import { instance } from '@api/instance';
import {
	SignInOAuthPayload,
	APIResponse,
	SignInOAuthResponse,
	BookingP2PPayload,
	CreateReviewPayload,
	CreateVacantPayload,
	BusyData,
	NotificationRegisterPayload,
	NotificationRegister,
	SurveysExpert
} from '@api/PayloadTypes';
import {
	ISearchExpertData,
	MyPaymentParams,
	PaginationParams,
	PaymentURLParams,
	ScheduleParams
} from './ParamsTypes';
import { Payment } from '@shared/models/Payment.model';
import { Wallet } from '@shared/models/Wallet.model';

import { VacantBlock } from '@shared/models/VacantBlock.model';
import { VacantExpert } from '@shared/models/VacantExpert.model';
import { Schedule, ScheduleInfo } from '@shared/models/Schedule.model';
import { Webinar, WebinarCollections } from '@shared/models/Webinar.model';
import { v4 as uuidv4 } from 'uuid';
import { Notification } from '@shared/models/Notification.model';
import { ShortsVideo } from '@shared/models/ShortsVideo.model';
import dayjs from 'dayjs';
import { pixieCollection } from '@shared/constant/collection';
import { getCookie, validLocale } from './locale';
import { IVoucher } from '@shared/models/Voucher.model';
import { Reviews } from '@containers/ExpertInfo/components/ExpertBio';
import { EmojisData } from '@containers/ExpertInfo/components/ExpertStaticCard';
import { QuestionsResponse } from '@containers/Home/Gamification';

const handleChatGPTError = (res: AxiosError) => {
	if (res.response && res.response.data && (res.response.data as any).message) {
		return Promise.reject((res.response.data as any).message);
	}

	throw res;
};
const handleRequestError = (res: AxiosError) => {
	if (res.response && res.response.data && (res.response.data as any).message) {
		toast.error((res.response.data as any).message);
	}

	throw res;
};
class ApplicationAPI {
	private readonly instance: AxiosInstance;
	private isPostProcessed: boolean;
	private locale: string;

	constructor(newInstance: AxiosInstance) {
		this.instance = newInstance;
		this.isPostProcessed = false;
		this.locale = 'vi';
	}

	endpointWithLocaleParam = (endpoint: string) => {
		const [host, params] = endpoint.split('?');
		const query = new URLSearchParams(params);
		if (!validLocale(this.locale)) return endpoint;
		query.append('locale', this.locale);
		return `${host}?${query.toString()}`;
	};

	removeAuthHeader() {
		delete this.instance.defaults.headers.common['Authorization'];
	}

	setToken(accessToken: string) {
		this.instance.defaults.headers.common = {
			Authorization: `Bearer ${accessToken}`,
			...this.instance.defaults.headers.common
		};
	}

	setRequestID(requestID: string) {
		this.instance.defaults.headers.common = {
			'X-Request-ID': requestID,
			...this.instance.defaults.headers.common
		};

		const localeCookie = getCookie('NEXT_LOCALE');
		if (localeCookie && validLocale(localeCookie)) {
			this.locale = localeCookie;
		}
	}

	postProcess() {
		// get request id
		if (typeof window !== 'undefined') {
			const requestID = localStorage.getItem('REQUEST_ID');
			if (requestID) {
				this.setRequestID(requestID);
			} else {
				const newRequestID = uuidv4();
				localStorage.setItem('REQUEST_ID', newRequestID);
				this.setRequestID(newRequestID);
			}
			this.isPostProcessed = true;
		}
	}

	// APIS

	// PROFILES --------------------------------

	signInOAuth(payload: SignInOAuthPayload) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		return this.instance.post<APIResponse<SignInOAuthResponse>>(API.SIGN_IN, {
			...payload,
			scope: 'app'
		});
	}

	getAuthenticatedProfile() {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const endpoint = this.endpointWithLocaleParam(`${API.PROFILE}/me`);
		return this.instance
			.get<APIResponse<Profile>>(endpoint)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	getProfileById = (id: string) => {
		return this.instance
			.get<APIResponse<Profile>>(this.endpointWithLocaleParam(`${API.PROFILE}/${id}`))
			.then((res) => res.data);
	};

	updateMyProfile = (updatedUser: Profile) => {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		return this.instance
			.patch(this.endpointWithLocaleParam(`${API.PROFILE}/me`), updatedUser)
			.then((res) => res.data)
			.catch(handleRequestError);
	};

	// EXPERIENCE --------------------------------
	createExperience = (payload: ExpertExperience) => {
		const endpoint = this.endpointWithLocaleParam(`/v1/experiences`);
		return this.instance
			.post(endpoint, payload)
			.then((res) => res.data)
			.catch(handleRequestError);
	};

	patchExperience = (experienceId: string, payload: ExpertExperience) => {
		const endpoint = this.endpointWithLocaleParam(`/v1/experiences/${experienceId}`);
		return this.instance
			.patch(endpoint, payload)
			.then((res) => res.data)
			.catch(handleRequestError);
	};

	deleteExperience = (experienceId: string) => {
		const endpoint = this.endpointWithLocaleParam(`/v1/experiences/${experienceId}`);
		return this.instance
			.delete(endpoint)
			.then((res) => res.data)
			.catch(handleRequestError);
	};

	// DEGREE --------------------------------
	createDegree = (payload: ExpertDegree) => {
		const endpoint = this.endpointWithLocaleParam(`/v1/degrees`);
		return this.instance
			.post(endpoint, payload)
			.then((res) => res.data)
			.catch(handleRequestError);
	};

	patchDegree = (degreeId: string, payload: ExpertDegree) => {
		const endpoint = this.endpointWithLocaleParam(`/v1/degrees/${degreeId}`);
		return this.instance
			.patch(endpoint, payload)
			.then((res) => res.data)
			.catch(handleRequestError);
	};

	deleteDegree = (degreeId: string) => {
		const endpoint = this.endpointWithLocaleParam(`/v1/degrees/${degreeId}`);
		return this.instance
			.delete(endpoint)
			.then((res) => res.data)
			.catch(handleRequestError);
	};

	// SEACH PROFILES

	getExperts = (params?: PaginationParams, cancel?: any) => {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const query = new URLSearchParams();
		query.append('type', 'expert');
		params?.limit && query.append('limit', params.limit.toString());
		params?.offset && query.append('offset', params.offset.toString());
		const endpoint = this.endpointWithLocaleParam(`${API.PROFILE}?${query.toString()}`);
		return this.instance
			.get<APIResponse<Profile[]>>(endpoint, {
				cancelToken: new axios.CancelToken((c) => {
					if (cancel) cancel = c;
				})
			})
			.then((res) => res.data);
	};

	getExpertsV2 = () => {
		if (!this.isPostProcessed) {
			this.postProcess();
		}

		const endpoint = `${API.V2_PROFILES}`;
		return this.instance.get<APIResponse<ExpertsByDomain[]>>(endpoint).then((res) => res.data);
	};

	searchExperts = (query: string) => {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const endpoint = this.endpointWithLocaleParam(
			`${API.SEARCH}/suggested_experts?query=${encodeURIComponent(query)}`
		);
		return this.instance.get<APIResponse<Profile[]>>(endpoint).then((res) => res.data);
	};

	searchExpert = (params: ISearchExpertData) => {
		if (!this.isPostProcessed) {
			this.postProcess();
		}

		return this.instance.get<
			APIResponse<{
				experts: Profile[];
				total: number;
			}>
		>(this.endpointWithLocaleParam(`${API.V2_SEARCH_SUGGESTION}`), {
			params: params
		});
	};

	getSuportFilterData = () => {
		if (!this.isPostProcessed) {
			this.postProcess();
		}

		return this.instance
			.get(this.endpointWithLocaleParam(`${API.SEARCH_V2}/supported_params`))
			.catch(handleRequestError);
	};

	surveyExpert = (payload: SurveysExpert) => {
		return this.instance
			.post(this.endpointWithLocaleParam(`${API.SURVEYS}`), payload)
			.then((res) => res.data)
			.catch(handleRequestError);
	};

	// P2Ps -----------------------------------

	createP2PBooking(payload: BookingP2PPayload) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const endpoint = this.endpointWithLocaleParam(API.P2PS);
		return this.instance
			.post<APIResponse<{ id: string }>>(endpoint, payload)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	// P2P vacants -----------------------------

	getVacantsByAccountId(account_id: string, from: number, to: number) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		// from to unix time
		const query = new URLSearchParams();
		query.append('account_id', account_id);
		query.append('from', from.toString());
		query.append('to', to.toString());
		const endpoint = this.endpointWithLocaleParam(`${API.VACANTS}?${query.toString()}`);

		return this.instance
			.get<AxiosResponse<VacantBlock[]>>(endpoint)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	getMyVacants() {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const endpoint = this.endpointWithLocaleParam(`${API.VACANTS}/me`);

		return this.instance
			.get<AxiosResponse<VacantExpert[]>>(endpoint)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	createVancant(payload: CreateVacantPayload) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const endpoint = this.endpointWithLocaleParam(API.VACANTS);

		return this.instance
			.post(endpoint, payload)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	deleteVacantById(id: string) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const endpoint = this.endpointWithLocaleParam(`${API.VACANTS}/${id}`);

		return this.instance
			.delete(endpoint)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	updateVacant(ids: string[], enable: boolean) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const endpoint = this.endpointWithLocaleParam(`${API.VACANTS}`);
		return this.instance
			.patch(endpoint, {
				id: ids,
				enable
			})
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	// P2P busy date ------------------------------

	getMyBusyDates() {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const endpoint = this.endpointWithLocaleParam(`${API.BUSY_DATES}/me`);

		return this.instance
			.get<AxiosResponse<Array<BusyData>>>(endpoint)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	createBusyDate(payload: { date: number }) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const endpoint = this.endpointWithLocaleParam(API.BUSY_DATES);

		return this.instance
			.post(endpoint, payload)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	deleteBusyDate(id: string) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const endpoint = this.endpointWithLocaleParam(`${API.BUSY_DATES}/${id}`);

		return this.instance
			.delete(endpoint)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	// Common (reviews)

	getExpertReviews(account_id: string, params?: PaginationParams) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const query = new URLSearchParams();
		query.append('account_id', account_id);
		params?.limit && query.append('limit', params.limit.toString());
		params?.offset && query.append('offset', params.offset.toString());
		const endpoint = this.endpointWithLocaleParam(`${API.REVIEWS}?${query.toString()}`);

		return this.instance
			.get<AxiosResponse<{ total: number; reviews: Reviews[] }>>(endpoint)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	createExpertReviews(payload: CreateReviewPayload) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const endpoint = this.endpointWithLocaleParam(API.REVIEWS);
		return this.instance
			.post(endpoint, payload)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	// Emojis
	getExpertEmojis(expert_id: string) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		// const query = new URLSearchParams();
		// query.append('expert_id', expert_id);

		const endpoint = this.endpointWithLocaleParam(`${API.EMOJIS}/${expert_id}`);

		return this.instance
			.get<AxiosResponse<EmojisData>>(endpoint)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	// Reschedule
	getRescheduleInfo = (id: string) => {
		return this.instance
			.get<APIResponse<ScheduleInfo>>(
				this.endpointWithLocaleParam(`${API.P2PS}/${id}/reschedule`)
			)
			.then((res) => res.data)
			.catch(handleRequestError);
	};

	updateSchedule(id: string, start: number) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const endpoint = this.endpointWithLocaleParam(`${API.P2PS}/${id}`);
		return this.instance
			.patch(endpoint, {
				status: 'rescheduled',
				start
			})
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	// WEBINAR --------------------------------

	getWebinars() {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const endpoint = this.endpointWithLocaleParam(API.WEBINARS);

		return this.instance
			.get<AxiosResponse<Webinar[]>>(endpoint)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	getWebinarCollectionById(id: string) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const endpoint = this.endpointWithLocaleParam(`${API.WEBINARS_COLLECTIONS}/${id}`);

		return this.instance
			.get<AxiosResponse<WebinarCollections>>(endpoint)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	getWebinarCollections() {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const endpoint = this.endpointWithLocaleParam(API.WEBINARS_COLLECTIONS);

		return this.instance
			.get<AxiosResponse<WebinarCollections[]>>(endpoint)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	getIndividualWebinars() {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const endpoint = this.endpointWithLocaleParam(
			`/v1/webinars?collection_id=${pixieCollection}`
		);

		return this.instance
			.get<AxiosResponse<Webinar[]>>(endpoint)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	joinWebinarCollectionById(colletionId: string, payload?: JoinWebinarsCollectionsPayload) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const endpoint = this.endpointWithLocaleParam(`/v1/collections/${colletionId}/join`);

		return this.instance
			.post(endpoint, payload)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	getWebinarDetail(id: string) {
		const endpoint = this.endpointWithLocaleParam(`${API.WEBINARS}/${id}`);

		return this.instance
			.get<AxiosResponse<Webinar>>(endpoint)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	joinWebinar(id: string, payload?: JoinWebinarsPayload) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const endpoint = this.endpointWithLocaleParam(`${API.WEBINARS}/${id}/join`);

		return this.instance
			.post<AxiosResponse<Webinar>>(endpoint, payload ? payload : {})
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	// VOUCHERS --------------------------------

	getAppliable(code: string, payload: GetAppliablePayload) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const endpoint = this.endpointWithLocaleParam(`${API.VOUCHERS}/${code}/get-appliable`);

		return this.instance
			.post<AxiosResponse<GetAppliableResponse>>(endpoint, payload)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	getVouchers(type: string, expertId: string, duration: string) {
		const query = new URLSearchParams();
		query.append('type', type);
		query.append('expert_id', expertId);
		query.append('duration', duration);
		const endpoint = this.endpointWithLocaleParam(
			`${API.APPLICABLE_VOUCHER}?${query.toString()}`
		);

		return this.instance
			.get<AxiosResponse<IVoucher[]>>(endpoint)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	// SCHEDULE --------------------------------

	getMySchedule(params?: ScheduleParams) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}

		const query = new URLSearchParams();
		params?.type && query.append('type', params.type.toString());
		params?.limit
			? query.append('limit', params.limit.toString())
			: query.append('limit', '24');
		params?.offset && query.append('offset', params.offset.toString());
		params?.order_by && query.append('order_by', params.order_by.toString());
		params?.order_dir && query.append('order_dir', params.order_dir.toString());

		if (params?.tab_query === 'upcoming') {
			query.append('from', dayjs(new Date()).unix().toString());
			query.append('status', 'new');
			query.append('status', 'paid');
		}
		if (params?.tab_query === 'completed') {
			query.append('to', dayjs(new Date()).unix().toString());
			query.append('status', 'paid');
			query.append('order_by', 'start');
			query.append('order_dir', 'desc');
		}
		if (params?.tab_query === 'canceled') {
			query.append('status', 'canceled');
			query.append('order_by', 'start');
			query.append('order_dir', 'desc');
		}

		const endpoint = this.endpointWithLocaleParam(`${API.SCHEDULES}/me?${query.toString()}`);
		return this.instance
			.get<AxiosResponse<Schedule[]>>(endpoint)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	getScheduleById(id: String) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const endpoint = this.endpointWithLocaleParam(`${API.SCHEDULES}/me?resource_id=${id}`);
		return this.instance
			.get<AxiosResponse<Schedule[]>>(endpoint)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	// REWARDS --------------------------------

	createQuestion(data: { name: string; phone: string; content: string }) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const endpoint = this.endpointWithLocaleParam(API.QUESTIONS);

		return this.instance
			.post(endpoint, data)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	getOwnQuestion(params?: PaginationParams, config?: AxiosRequestConfig) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const endpoint = this.endpointWithLocaleParam(`${API.QUESTIONS}/me`);

		return this.instance
			.get<AxiosResponse<QuestionsResponse>>(endpoint, { params, ...config })
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	// FILES --------------------------------

	getUpDownURL(fileType: string, fileName: string) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		return instance
			.get<APIResponse<UpDownURL>>(
				this.endpointWithLocaleParam(
					`${API.FILES}/up_down_urls?file_type=${fileType}&file_name=${fileName}`
				)
			)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	upLoadImageFromURL(presignedS3Url: string, file: File) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const uploadAvatarAxios = axios.create({
			headers: {
				'Content-Type': file.type
			}
		});
		return uploadAvatarAxios
			.put(presignedS3Url, file)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	// ORGANIZATIONS --------------------------------

	// Wallet / Payment / Transitions

	getPaymentURL(params: PaymentURLParams) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const query = new URLSearchParams();
		query.append('resource_id', params.resource_id);
		query.append('locale', params.locale);
		query.append('type', params.type || 'booking');
		query.append('return_url', params.return_url);
		const endpoint = this.endpointWithLocaleParam(`${API.TRANSACTIONS}?${query.toString()}`);

		return this.instance
			.get<APIResponse<string>>(endpoint)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	getMyPaymentStatus(params: MyPaymentParams) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const query = new URLSearchParams();
		params.resource_id && query.append('resource_id', params.resource_id);
		params.direction && query.append('direction', params.direction);
		params.target && query.append('target', params.target);
		params.status && query.append('status', params.status);
		params.type && query.append('type', params.type);
		params.created_at_from && query.append('created_at_from', params.created_at_from);
		params.created_at_to && query.append('type', params.created_at_to);
		const endpoint = this.endpointWithLocaleParam(`${API.TRANSACTIONS}/me?${query.toString()}`);

		return this.instance
			.get<APIResponse<Payment[]>>(endpoint)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	getMyWallet() {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		const endpoint = this.endpointWithLocaleParam(`${API.WALLETS}/me`);

		return this.instance
			.get<APIResponse<Wallet>>(endpoint)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	// NOTIFICATIONS ---------------------------------------------------------------------------------
	notificationRegister(payload: NotificationRegisterPayload): Promise<NotificationRegister> {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		return this.instance
			.post(this.endpointWithLocaleParam(API.NOTIFICATIONS_REGISTER), { ...payload })
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	// EVENTS -------------------------------------------------------------------------------------------
	sendEvent(payload: EventPayload): Promise<any> {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		return this.instance
			.post(this.endpointWithLocaleParam(API.EVENTS), { ...payload })
			.then((res) => res.data);
	}

	// Deeplinks -------------------------------------------------------------------------------------------
	getDeeplink(id: string) {
		return this.instance
			.get<APIResponse<{ url: string }>>(
				this.endpointWithLocaleParam(`${API.DEEPLINKS}/${id}`)
			)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	// Nickname -------------------------------------------------------------------------------------------
	getNickname(nickname: string) {
		return this.instance
			.get<APIResponse<Nickname>>(this.endpointWithLocaleParam(`${API.NICKNAME}/${nickname}`))
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	// Notifications ---------------------------------------------------------------------------------------
	getMyNotifications(params?: PaginationParams) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		return this.instance
			.get<APIResponse<Notification[]>>(
				this.endpointWithLocaleParam(`${API.NOTIFICATION}/me`),
				{ params }
			)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	seenMyNotifications(id: string) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		return this.instance
			.patch(this.endpointWithLocaleParam(`${API.NOTIFICATIONS}/${id}`))
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	seenAllMyNotifications() {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		return this.instance
			.post<any>(this.endpointWithLocaleParam(`${API.NOTIFICATIONS}/me/read_all`))
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	// VIDEOS ---------------------------------------------------------------------------------
	getShortsVideo(params?: PaginationParams) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		return this.instance
			.get<APIResponse<ShortsVideo>>(
				this.endpointWithLocaleParam(`${API.VIDEOS}?search_type=all`),
				{ params }
			)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	getTopicSettigns() {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		return this.instance
			.get<APIResponse<TopicSettingResponse[]>>(this.endpointWithLocaleParam(`${API.TOPIC}`))
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	updateTopicSettigns(payload: TopicSettingPayload[]) {
		if (!this.isPostProcessed) {
			this.postProcess();
		}
		return this.instance
			.post(this.endpointWithLocaleParam(`${API.TOPIC}`), payload)
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	sendChat<T>(payload: IChatPayload) {
		return this.instance
			.post<APIResponse<T>>(this.endpointWithLocaleParam(`${API.CHAT_STREAM}`), payload)
			.then((res) => res.data)
			.catch(handleChatGPTError);
	}

	getChat() {
		return this.instance
			.get(this.endpointWithLocaleParam(`${API.CHAT}/me`))
			.then((res) => res.data)
			.catch(handleRequestError);
	}
	streamChat<T>(id: string) {
		return this.instance
			.get<APIResponse<T>>(this.endpointWithLocaleParam(`${API.CHAT}/${id}`))
			.then((res) => res.data)
			.catch(handleRequestError);
	}

	updatePhoneNumber(phone: string) {
		return this.instance.patch(this.endpointWithLocaleParam(`${API.PROFILE}/me`), { phone });
	}
}

export const api = new ApplicationAPI(instance);
