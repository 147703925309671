export enum API {
	// V1
	SIGN_IN = '/v1/accounts/login',
	PROFILE = '/v1/profiles',
	P2PS = '/v1/p2ps',
	VACANTS = '/v1/p2ps/vacants',
	BUSY_DATES = '/v1/p2ps/busy_dates',
	SURVEYS = '/v1/p2ps/surveys',
	SCHEDULES = '/v1/schedules',
	WEBINARS = '/v1/webinars',
	WEBINARS_COLLECTIONS = '/v1/webinar_collections',
	REVIEWS = '/v1/reviews',
	TRANSACTIONS = '/v1/transactions',
	WALLETS = '/v1/wallets',
	ORGANIZATIONS = '/v1/organizations',
	FILES = '/v1/files',
	DOMAIN = '/v1/domains',
	EVENT = '/v1/webinars',
	NOTIFICATIONS_REGISTER = '/v1/notifications/register',
	SUPPORT_PARAMS = '/v2/search/supported_params',
	NOTIFICATION = '/v1/notifications',
	SEARCH = '/v1/search',
	SEARCH_V2 = '/v2/search',
	DEEPLINKS = '/v1/deeplinks',
	NICKNAME = '/v1/nicknames',
	EVENTS = '/v1/events',
	NOTIFICATIONS = '/v1/notifications',
	APPLICABLE_VOUCHER = '/v1/applicable-vouchers',
	EMOJIS = '/v1/emojis',
	QUESTIONS = '/v1/questions',

	// V2
	VIDEOS = '/v2/videos',
	V2_PROFILES = '/v2/profiles',
	V2_SEARCH_SUPPORTED_PARAMS = '/v2/search/supported_params',
	V2_SEARCH_SUGGESTION = '/v2/search/suggestion',
	TOPIC = '/v1/topics',
	VOUCHERS = '/v1/vouchers',
	CHAT = '/v1/chat',
	CHAT_STREAM = '/v1/chat-stream'
}
